<template>
  <div class="team">
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <v-row align="center" justify="center">
          <h3 class="mr-3 text--bold my-5"> اختيار </h3>
        </v-row>
        <v-form v-model="isFormValidAdd">
          <!-- ClassSelect -->
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-autocomplete v-model="data.chooseClass" :items="class_select" :loading="classLoading"
                :item-text="item => item.class_name + ' - ' + item.leader" :rules="addRules.classRules" clearable
                label="الصف والشعبة" outlined dense return-object></v-autocomplete>
            </v-col>
          </v-row>
          <!-- SubjectPick -->
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-autocomplete v-model="data.subject" clearable :rules="addRules.subject" :items="subjectItem"
                label="اسم المادة" item-text="subject_name" item-value="subject_name" :loading="subjectLoading" outlined
                dense></v-autocomplete>
            </v-col>
          </v-row>
          <!-- DatePick -->
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-menu v-model="menuAccountStartDate" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="data.date" :rules="addRules.date" label="التاريخ" outlined dense clearable
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="data.date" @input="menuAccountStartDate = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- MaxDegree -->
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-text-field v-model="data.maxDegree" :rules="addRules.maxDegree" label="درجة الامتحان القصوى" outlined
                clearable dense @keypress="isNumber($event)"></v-text-field>
            </v-col>
          </v-row>
          <!-- note -->
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <!-- <v-text-field v-model="data.note" label="ملاحظة الامتحان" outlined clearable dense
                @keypress="isNumber($event)"></v-text-field> -->
              <v-textarea v-model="data.note" rows="3" label="ملاحظة الامتحان" outlined clearable dense></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6">
            <v-btn depressed color="primary" :disabled="!isFormValidAdd" :loading="addLoading" @click="submitAdd"> اضافة
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="27.7778rem">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{  dialogData.bodyText  }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from '@/api/api'

export default {
  data() {
    return {
      isFormValidAdd: false,

      addLoading: false,

      menuAccountStartDate: false,

      class_select: [],

      leader_select: [],

      leader_select_filter: [],

      subjectItem: [],

      data: {
        chooseClass: null,
        chooseLeader: null,
        maxDegree: 10,
        subject: null,
        note: null,
        date: this.getCurrentDate(),
      },


      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      classLoading: false,

      subjectLoading: false,

      addRules: {
        classRules: [value => !!value || 'يجب اختيار الصف'],
        leaderRules: [value => !!value || 'يجب اختيار الشعبة'],
        date: [value => !!value || 'يجب اختيار التاريخ'],
        maxDegree: [value => !!value || 'يجب كتابة درجة الامتحان القصوى'],
        subject: [value => !!value || 'يجب اختيار المادة'],
      },
      selected_class_school: {},
    }
  },
  created() {
    this.getClassSchool()
    this.getSubjectDataAxios()
  },

  methods: {

    async submitAdd() {
      this.addLoading = true

      const study_year = JSON.parse(localStorage.getItem("study_year"));

      const response = await Api.addDailyExams(this.data.subject, this.data.maxDegree, this.data.date, this.data.note, study_year, this.data.chooseClass._id)

      if (response.status === 401) {
        this.addLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.addLoading = false
        this.showDialogfunction(response.data.results, 'primary')
        // this.goToAbsensePage(this.data.chooseLeader, this.data.subject, this.data.date, this.data.chooseClass.class_name, this.data.chooseClass.leader, this.data.maxDegree, response.data._id)
      }
    },



    // goToAbsensePage(class_id, subject, date, class_name, leader, maxDegree, exams_id) {
    //   this.$router.push(
    //     `/dailyExamAdd/class_id/${class_id}/subject/${subject}/date/${date}/class_name/${class_name}_${leader}/maxDegree/${maxDegree}/exams_id/${exams_id}`,
    //   )
    // },


    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },



    getCurrentDate() {
      const d = new Date()
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) {
        month = `0${month}`
      }
      if (day.length < 2) {
        day = `0${day}`
      }

      return [year, month, day].join('-')
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },


    async getClassSchool() {
      this.classLoading = true

      const response = await Api.getClassSchool()
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.classLoading = false

        this.class_select = response.data.results
        // this.leader_select = response.data.results
      }
    },


    async getSubjectDataAxios() {
      this.subjectLoading = true

      const response = await Api.getSubject()
      if (response.status === 401) {
        this.subjectLoading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.subjectLoading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.subjectLoading = false
        this.subjectItem = response.data.results
        // this.leader_select = response.data.results
      }
    },
  },
}
</script>
